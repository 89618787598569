import { Form } from "@quillforms/renderer-core";
import axios from "axios";
import "./index.css";

import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { useSearchParams } from "react-router-dom";

registerCoreBlocks();

const Fundamentals = () => {
  const [searchParams] = useSearchParams();
  return (
    <div
      style={{ width: "100%", height: "100vh" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          document.getElementsByClassName("renderer-core-button")[0].click();
        }
      }}
    >
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "email",
              id: "email",
              attributes: {
                required: true,
                label:
                  "<strong>Ready to take a deeper dive into the fundamentals of crowdfunding and your internship?</strong>",
                description: "Share your email ID with us.",
              },
            },
            {
              name: "number",
              id: "number",
              attributes: {
                required: true,
                label: "<strong>And your WhatsApp number?</strong>",
                description: "So we know you filled the form already!:)",
                setMin: true, // Default: false
                min: 4999999999, // Default: 0
                setMax: true, // Default: false
                max: 9999999999,
              },
            },
            {
              name: "statement",
              id: "jfyulv1r",
              attributes: {
                required: false,
                buttonText: "How Does It Work?",
                label:
                  "<strong>Crowdfunding means collecting resources to gather necessary support for an idea, an initiative, or a project.</strong>",
                description: `Organizations, musicians, physicists, and individuals from all walks of life have used crowdfunding to bring their vision to life.`,
              },
            },
            {
              name: "statement",
              id: "jfkkbj1fr",
              attributes: {
                buttonText: "Once upon a time...",
                label: `<strong>Over the course of 8 years...<strong>.`,
                description: `We have built Project KeyTAAB on three pillars of child development. It is supported by a team of 47 professional tutors in Mumbai and Ranchi. We educate 477 children daily.`,
              },
            },
            {
              name: "statement",
              id: "jfasvsde",
              attributes: {
                buttonText: "And then?",
                label: `<strong>We simply started by showing up for the kids, conducting weekend activities, and looking after them.</strong>`,
                description: `We wanted to give them happy experiences and extend our warmth.                `,
              },
            },
            {
              name: "statement",
              id: "jfgsdffr",
              attributes: {
                buttonText: "But how?",
                label: `<strong>Today, our model has the capacity to efficiently fill the gap in quality education for underprivileged children.</strong>`,
                description: `Project KeyTAAB takes care of their academics through experiential learning, social-emotional learning and mental welling.`,
              },
            },
            {
              name: "multiple-choice",
              id: "guess",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label: "<strong>Take a guess.</strong>",
                description:
                  "How does a 17-year-old young boy with the help of his friends build an organization in 8 years that is equipped to provide quality education to hundreds of underprivileged children?",
                choices: [
                  {
                    label: `Crowdfunding`,
                    value: "crowdfunding",
                  },
                  {
                    label: "Personal Investment",
                    value: "personal-investment",
                  },
                  {
                    label: "Government",
                    value: "government",
                  },
                ],
              },
            },
            {
              name: "statement",
              id: "kad401r",
              attributes: {
                buttonText: "Braavo!",
                label: `<strong>Crowdfunding! That's How.</strong>`,
                description: `We reached out to the people in our network, we told them about the cause, we showed them our growth and we found people who felt compelled to support us and they <strong>FUNDED US.</strong>`,
              },
            },
            {
              name: "statement",
              id: "gaca401r",
              attributes: {
                buttonText: "Tell me more!",
                label: `<strong>We are also authorized by the GOI to raise donations.</strong>`,
                description: `We have an <strong>80G</strong> certificate issued by the <strong>Income Tax Department</strong>. It allows us to give <strong>tax benefits to the donors.</strong>`,
              },
            },
            {
              name: "statement",
              id: "gaasd401r",
              attributes: {
                buttonText: "Sounds, legit!",
                label: `<strong>The Bombay Public Trust Act, 1950.</strong>`,
                description: `Being a certified NGO means that we are directly answerable to the GOI. Our financial reports (Audits) are publicly available online.`,
              },
            },
            {
              name: "statement",
              id: "f4aa01r",
              attributes: {
                buttonText: "Ok, wow!",
                label: `<strong>80G Certification</strong> allows us to give 50% tax exemption benefits to the donors.`,
                description: `For example, Sameer needs to pay taxes worth Rs. 1,00,000 in a finanical year. But Sameer donated Rs. 50,000 to an NGO that year which gives him an exemption of Rs. 25000 (50% of 50000) from his taxable amount. Now, he has to pay tax on only Rs. 75,000.`,
              },
            },
            {
              name: "short-text",
              id: "opinion",
              attributes: {
                required: true,
                label: "What is your opinion on Crowdfunding for the cause?",
              },
            },
            {
              name: "statement",
              id: "lolkj",
              attributes: {
                buttonText: "To Live is to Give!",
                label: `<strong>Our world is unfairly divided.</strong>`,
                description: `Crowdfunding has enabled us to meet individuals with the means necessary to serve the cause. The one with the intention and capacity to give back to the society.`,
              },
            },
            {
              name: "statement",
              id: "lolasdasdkj",
              attributes: {
                buttonText: "Continue",
                label: `<strong>A Story To Tell...</strong>`,
                description: `Every idea that was backed by people received funding because they were communicated with passion. These are the stories that need to be heard, there are initiatives that needs the power of people.`,
              },
            },
            {
              name: "statement",
              id: "aadkj",
              attributes: {
                buttonText: "I would love to!",
                label: `Muskurahat is the story of the hundreds of children who <strong>DESERVE</strong> quality education.`,
                description: `It's the story of bringing up children in a fairly unfair world. The question is, will you tell it for us?`,
              },
            },
            {
              name: "multiple-choice",
              id: "legitimate",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label:
                  "It ain't that easy! It depends on the attention you are willing to give to the cause. So let's test your attention.",
                description: "What legitimises crowdfunding for a NGO?",
                choices: [
                  {
                    label: "Authorisation by GOI",
                    value: "authorisation-by-goi",
                  },
                  {
                    label: "The Bombay Act, 1950",
                    value: "the-bombay-act-1950",
                  },
                  {
                    label: "80G Certificates",
                    value: "80g-certificates",
                  },
                  {
                    label: "All of the above",
                    value: "all-of-the-above",
                  },
                ],
              },
            },
            {
              name: "statement",
              id: "aadasdkj",
              attributes: {
                buttonText: "Ask me a difficult one!",
                label: `All of the above.`,
                description: `Pat yourself on the back if you got that right.`,
              },
            },
            {
              name: "short-text",
              id: "tax-benefit",
              attributes: {
                required: false,
                label: "What % Tax benefit do donors receive and why?",
              },
            },
            {
              name: "statement",
              id: "aagsdkj",
              attributes: {
                buttonText: "Ok, let's get started!",
                label: `Ok, we believe you got this!`,
                description: `We would like to tell you about the role of a crowdfunding intern.`,
              },
            },
            {
              name: "statement",
              id: "hhsdkj",
              attributes: {
                buttonText: "Bring It!",
                label: `We have a <strong>CODE OF CONDUCT</strong>, you <strong>MUST</strong> follow.`,
                description: `Are you up for it?`,
              },
            },
            {
              name: "statement",
              id: "hjnkj",
              attributes: {
                buttonText: "Noted.",
                label: `<strong>Every week, we will have 1 update round.</strong>`,
                description: `Updating is a necessity for being certified. `,
              },
            },
            {
              name: "statement",
              id: "hknkjnkj",
              attributes: {
                buttonText: "Noted.",
                label: `<strong>You must attend all the meetings for successfully completing your internship.</strong>`,
                description: `You will be meeting our leadership team, connecting with your peers, discussing your internship challenges and learning deeply during these sessions.`,
              },
            },
            {
              name: "statement",
              id: "fagnkj",
              attributes: {
                buttonText: "Noted.",
                label: `All your <strong>doubts and queries SHOULD</strong> be addressed in the <strong>personal chat</strong> only with your <strong>reporting manager, Samay.</strong>`,
                description: `You will soon receive a Whatsapp from <strong>+91 835 686 2390!</strong>`,
              },
            },
            {
              name: "statement",
              id: "fgasjnkj",
              attributes: {
                buttonText: "Noted.",
                label: `<strong>You are not allowed to use LINKEDIN for your campaign.</strong>`,
                description: `We already have a team of professionals focused on CSR. Do not go ahead randomly texting people you think can help you with donations. You must your personal network only.`,
              },
            },
            {
              name: "statement",
              id: "hkkj",
              attributes: {
                buttonText: "Noted.",
                label: `<strong>Do not share incorrect information, stock images, or false narratives with your potential donors. Do NOT force your donors.</strong>`,
                description: `Before sharing anything on social media, its better to take approval from your reporting manager, who can guide you with the same.`,
              },
            },
            {
              name: "short-text",
              id: "josh",
              attributes: {
                required: false,
                label:
                  "<strong>You will find us every step of the way to help you!</strong><br/>How likely are you to ace your internship after learning the fundamentals of crowdfunding?",
                description: `Translation: How's the josh?`,
              },
            },
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
          },
          messages: {
            "block.email.placeholder": "name@example.com",
            "block.number.placeholder": "84510 61887",
            "block.defaultThankYouScreen.label": `<span style="font-size: 36px"><strong>Your one step today is going to change the future of many.</strong></span></br><span style="font-size: 20px;">We are going to reach out to you within 48 hours. Meanwhile do share the word, and follow us on <strong><i><a style="color: #000" href="https://www.instagram.com/muskurahat_foundation/">Instagram</a></i></strong> to learn more about how are we serving our children.</span>`,
            "label.errorAlert.minNum": "Please enter a 10 digit phone number",
            "label.errorAlert.maxNum": "Please enter a 10 digit phone number",
          },
          theme: {
            font: "Poppins",
            buttonsBgColor: "#574191",
            logo: {
              src: "https://muskurahat.org.in/wp-content/uploads/2022/12/MuskLogo.png",
            },
            backgroundImage:
              "https://images.typeform.com/images/d99r3WNhDKpE/background/large",
            backgroundColor: "#fff00",

            questionsColor: "#000",
            answersColor: "#574191",
            buttonsFontColor: "#fff",
            // questionsLabelFontSize: {
            //   lg: "36px",
            //   sm: "30px",
            // },
            buttonsBorderRadius: 5,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        onSubmit={async (data, { completeForm, setIsSubmitting }) => {
          let formData = new FormData();
          formData.append(
            "createdOn",
            `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`
          );

          formData.append("college", searchParams.get("college"));
          formData.append("poc", searchParams.get("poc"));

          formData.append("email", data.answers.email.value);
          formData.append("number", data.answers.number.value);
          formData.append("guess", data.answers.guess.value);
          formData.append("opinion", data.answers.opinion.value);
          formData.append("legitimate", data.answers.legitimate.value);
          formData.append("tax-benefit", data.answers["tax-benefit"].value);
          formData.append("josh", data.answers.josh.value);

          await axios.post(
            "https://script.google.com/macros/s/AKfycby5a8wkiAyX29va_RPWdR93gR095NCp7P18J9pyYaHpcHS_bc1dnRX-HL8NXO4NrQxVwg/exec",
            formData
          );
          setIsSubmitting(false);
          completeForm();
          // setTimeout(() => {
          //   setIsSubmitting(false);
          //   completeForm();
          // }, 500);
        }}
      />
    </div>
  );
};

export default Fundamentals;
