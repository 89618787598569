import { Form } from "@quillforms/renderer-core";
import axios from "axios";
import "./index.css";

import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "@quillforms/renderer-core/build-style/style.css";
import { useSearchParams } from "react-router-dom";

registerCoreBlocks();

const Internship = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("college"));
  return (
    <div
      style={{ width: "100%", height: "100vh" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          document.getElementsByClassName("renderer-core-button")[0].click();
        }
      }}
    >
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "jg1401r",
              attributes: {
                buttonText: `Become An Intern`,
                buttonBgColor: "#f00",
                label: `<span style="font-size: 36px"><strong>Hello Fellows,</br> The Future Needs Your Efforts!</strong></span>`,
                description: `Gather support for one of the most important causes in the society, to give quality education to the underprivileged. <strong>#EducationCan'tWait</strong>`,
                // attachment: {
                //   type: "image",
                //   url: "https://quillforms.com/wp-content/uploads/2022/01/4207-ai-1.jpeg",
                // },
                // attachmentMaxWidth: "300px",
              },
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                // attachment: {
                //   type: "image",
                //   url: "https://quillforms.com/wp-content/uploads/2022/10/ludovic-migneault-B9YbNbaemMI-unsplash_50-scaled.jpeg",
                // },
                // layout: "split-right",
                required: true,
                label:
                  "<strong>The change starts with you</strong> and you can start by telling us your <strong>NAME</strong>",
                description: "(TYPE YOUR FULL NAME BELOW)",
              },
            },
            {
              name: "email",
              id: "email",
              attributes: {
                required: true,
                label: "Where can we reach you?",
              },
            },
            {
              name: "number",
              id: "number",
              attributes: {
                required: true,
                label: "We would love to say hello on Whatsapp!",
                setMin: true, // Default: false
                min: 4999999999, // Default: 0
                setMax: true, // Default: false
                max: 9999999999,
              },
            },
            {
              name: "statement",
              id: "jfyulv1r",
              attributes: {
                required: false,

                buttonText: "Why?",
                label:
                  "<strong>Muskurahat Foundation is a force of youth, working towards future-proofing the next generation, today.</strong>",
                description: `We have been going the extra mile in supporting the education of children from under resourced backgrounds. Every day.`,
              },
            },
            {
              name: "statement",
              id: "jfkkbj1fr",
              attributes: {
                buttonText: "How is that?",
                label: `We don’t just cater to the educational needs of our children. We emphasize the word, <strong>QUALITY</strong>.`,
                description: `Countries across the world have proved that quality education ensures the well-being of a society.`,
              },
            },
            {
              name: "statement",
              id: "jfasvsde",
              attributes: {
                buttonText: "Boy, that's deep!",
                label: `<strong>Remember when we said we are constantly challenging the conventional?</strong>`,
                description: `We meet the holistic development needs of a child. We work on three key aspects - Social Emotional Learning, Academic Support and Mental Well-Being.`,
              },
            },
            {
              name: "statement",
              id: "jfgsdffr",
              attributes: {
                buttonText: "What can be done?",
                label: `To ensure our children grow into socially responsible adults <strong>we must react!</strong></br>
              Now is the <strong>time to take action!</strong>`,
                description: `#EducationCan'tWait`,
              },
            },
            {
              name: "statement",
              id: "kad401r",
              attributes: {
                buttonText: "Tell me MORE!",
                label: `Do you know 50% applicants drop out before we can answer that? We are grateful you are still with us!`,
                description: `<strong>The future will remember you!</strong>`,
              },
            },
            {
              name: "statement",
              id: "gaca401r",
              attributes: {
                buttonText: "What's next?",
                label: `<strong>This is a virtual crowdfunding internship.</strong>`,
                description: `You will be responsible for raising donations, awareness and advocating the cause in your network. You will be gathering funds to support education of thousands of children.`,
              },
            },
            {
              name: "multiple-choice",
              id: "consent-1",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label:
                  "During this internship you will be <strong>raising funds</strong> to enable us to educate thousands of children from underserved communities.",
                choices: [
                  {
                    label: "I accept",
                    value: "accept",
                  },
                  {
                    label: "I don't accept",
                    value: "reject",
                  },
                ],
              },
            },
            {
              name: "multiple-choice",
              id: "consent-2",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label:
                  "You will be required to reach out to your personal network, tell them about the cause and raise funds.",
                choices: [
                  {
                    label: "I accept",
                    value: "accept",
                  },
                  {
                    label: "I don't accept",
                    value: "reject",
                  },
                ],
              },
            },
            {
              name: "statement",
              id: "galksdm1r",
              attributes: {
                buttonText: "What will I learn?",
                label: `You are nothing short of a present for the future of our children. We are glad you made the choice to join us.`,
                description: `Also take home a letter of recommendation, certified training in crowdfunding and stipends.`,
              },
            },
            {
              name: "statement",
              id: "gagaddm1r",
              attributes: {
                buttonText: "I Cannot Wait!",
                label: `Learn to design your campaigns, pitch ideas develop interpersonal skills, certified course in crowdfunding, creative ways of raising funds and problem solving.`,
                description: `We will ensure that you learn to ace your crowdfunding internship.`,
              },
            },
            {
              name: "multiple-choice",
              id: "consent-3",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label:
                  "You will be required to maintain 2-way communication on Whatsapp, throughout your internship.",
                choices: [
                  {
                    label: "I accept",
                    value: "accept",
                  },
                  {
                    label: "I don't accept",
                    value: "reject",
                  },
                ],
              },
            },
            {
              name: "multiple-choice",
              id: "consent-4",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label: "Would you like to sign up for this internship?",
                choices: [
                  {
                    label: "Yes",
                    value: "yes",
                  },
                  {
                    label: "No",
                    value: "no",
                  },
                  {
                    label: "Maybe",
                    value: "maybe",
                  },
                ],
              },
            },
            {
              name: "short-text",
              id: "city",
              attributes: {
                required: true,
                label: "Which city are you from?",
              },
            },
            {
              name: "short-text",
              id: "qualification",
              attributes: {
                required: true,
                label: "Your educational qualifications?",
              },
            },
            {
              name: "long-text",
              id: "why",
              attributes: {
                required: true,
                label:
                  "Why would you like to help the <strong>thousands of children</strong> that are in need of quality education?",
                description:
                  "Before we meet again, we would like to hear from you and NOT CHATGPT ;) (Please answer by yourself and with minimum 10 words)",
              },
            },
            {
              name: "short-text",
              id: "stream",
              attributes: {
                required: false,
                label: "Your Stream/Course?",
                description: "(Optional)",
              },
            },
            // {
            //   name: "short-text",
            //   id: "enrollment-number",
            //   attributes: {
            //     required: false,
            //     label: "Your Enrollment Number?",
            //     description: "(Optional)",
            //   },
            // },
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
          },
          messages: {
            "block.email.placeholder": "name@example.com",
            "block.number.placeholder": "84510 61887",
            "block.defaultThankYouScreen.label": `<span style="font-size: 36px"><strong>Your one step today is going to change the future of many.</strong></span></br><span style="font-size: 20px;">We are going to reach out to you within 48 hours. Meanwhile do share the word, and follow us on <strong><i><a style="color: #000" href="https://www.instagram.com/muskurahat_foundation/">Instagram</a></i></strong> to learn more about how are we serving our children.</span>`,
          },
          theme: {
            font: "Poppins",
            buttonsBgColor: "#574191",
            logo: {
              src: "https://muskurahat.org.in/wp-content/uploads/2022/12/MuskLogo.png",
            },
            backgroundImage:
              "https://images.typeform.com/images/d99r3WNhDKpE/background/large",
            backgroundColor: "#fff00",

            questionsColor: "#000",
            answersColor: "#574191",
            buttonsFontColor: "#fff",
            // questionsLabelFontSize: {
            //   lg: "36px",
            //   sm: "30px",
            // },
            buttonsBorderRadius: 5,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        onSubmit={async (data, { completeForm, setIsSubmitting }) => {
          let formData = new FormData();
          formData.append(
            "createdOn",
            `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`
          );
          formData.append("college", searchParams.get("college"));
          formData.append("poc", searchParams.get("poc"));
          formData.append("name", data.answers.name.value);
          formData.append("email", data.answers.email.value);
          formData.append("number", data.answers.number.value);
          formData.append("consent-1", data.answers["consent-1"].value);
          formData.append("consent-2", data.answers["consent-2"].value);
          formData.append("consent-3", data.answers["consent-3"].value);
          formData.append("consent-4", data.answers["consent-4"].value);
          formData.append("city", data.answers.city.value);
          formData.append("qualification", data.answers.qualification.value);
          formData.append("why", data.answers.why.value);
          formData.append("stream", data.answers.stream.value);
          // formData.append(
          // "enrollment-number",
          // data.answers["enrollment-number"].value
          // );
          await axios.post(
            "https://script.google.com/macros/s/AKfycbyTi5EdQu1F-ynJ9XDqQKmV-EJBojiYOqd3ZugnZRhHZmzYv2eGDpwm5E27uyvJekbtFw/exec",
            formData
          );
          setIsSubmitting(false);
          completeForm();
          // setTimeout(() => {
          //   setIsSubmitting(false);
          //   completeForm();
          // }, 500);
        }}
      />
    </div>
  );
};

export default Internship;
