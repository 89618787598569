import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Internship from "./pages/internship";
import Fundamentals from "./pages/fundamentals";
import Intern from "./pages/intern";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/internship" />,
    },
    {
      path: "/internship",
      element: <Internship />,
    },
    {
      path: "/intern",
      element: <Intern />,
    },
    {
      path: "/fundamentals-of-crowdfunding",
      element: <Fundamentals />,
    },
    {
      path: "*",
      element: <Navigate to="/internship" />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
